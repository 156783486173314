/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import smoothscroll from "smoothscroll-polyfill"
import { attachSwipeEvents } from "./src/components/utils/swipeEvents"
import "./src/styles/fonts.css"

if (typeof document !== "undefined") {
  smoothscroll.polyfill()
  attachSwipeEvents(document)
}
